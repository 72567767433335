import React from "react";
import { Link } from "react-router-dom";
import {
  SumanFaculties,
  AnneshaFaculties,
  AtanuFaculties,
  SubhamFaculties,
  ArnabFaculties,
  RahulFaculties,
  SudiptaFaculties,
  NazmulFaculties,
  ArijitFaculties,
  ManasFaculties,
  MouliFaculties,
} from "../images";

const Faculties = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Faculties</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Faculties</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={SumanFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Suman Kanrar</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Principal</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: M.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 5 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Pharmaceutics</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932023</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={AnneshaFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Annesha Acharyya</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Vice-Principal</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: M.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 2.6 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Pharmaceutics</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932024</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={AtanuFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Atanu Acharyya</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Assistant Professor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: M.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 2.5 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Pharmacology</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932023</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={SubhamFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Subham Prasad</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Assistant Professor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: M.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 0.1 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Pharmaceutics</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932024</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={ArnabFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Arnab Sadhukhan</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Assistant Professor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: M.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 0.1 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Pharmacology</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932023</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={RahulFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Rahul Dey</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Assistant Professor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 3 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932024</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={SudiptaFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Sudipta Manna</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Assistant Professor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: M.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 0.1 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Pharmacology</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932023</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={NazmulFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Md Nazmul Ahamed Mallick</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Assistant Professor</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: M.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 3 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Pharmacology</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932024</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={MouliFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Mouli Mukherjee</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Lecturar</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Experience</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 0.1 Years</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Pharmacy</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932023</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={ArijitFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Arijit Ghosh</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Lab Technician</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Pharmacy</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932024</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="main-faculties">
                <div className="faculties-img">
                  <img src={ManasFaculties} alt="" />
                </div>
                <div className="faculties-content">
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Name</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Manas Maji</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Designation</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Lab Technician</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Qualification</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: B.Pharm</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Department</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: Pharmacy</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">Mobile No</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: 7601932023</p>
                    </div>
                  </div>
                  <div className="faculties-div">
                    <div className="faculties-left">
                      <p className="mb-0">E-mail</p>
                    </div>
                    <div className="faculties-right">
                      <p className="mb-0">: beop.principal@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faculties;
