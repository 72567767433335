import React from "react";
import { Link } from "react-router-dom";
import { FacilitiesImg } from "../images";

const Facilities = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Facilities</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Facilities</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="infrastructure-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="infrastructure-content">
                <h3>The Campus :</h3>
                <p>
                  The institute is situated at Bandhersole on NH-60 near
                  Bakreswar Thermal power plant and between Suri and Dubrajpur.
                  The well designed campus prevailed with green plants all
                  around, creating a beautiful atmosphere.
                </p>
                <h3>Buildings and facilities in the campus</h3>
                <p>
                  Class rooms, tutorial rooms, library, reading room, well
                  equipped laboratories for individual subjects, separate common
                  rooms for boys and girls, cycle & vehicle stand , play ground,
                  high range Wi-Fi facility in entire campus.
                </p>
              </div>
            </div>
            <div className="col-lg-4 align-self-center">
              <img src={FacilitiesImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facilities;
