import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { gallerySection } from "../Service/Home";

const Gallery = () => {
  const [holdGalleryData, setHoldGalleryData] = useState([]);
  const getGallery = async () => {
    try {
      const res = await gallerySection();
      setHoldGalleryData(res?.data?.data?.attributes?.files?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getGallery();
  }, []);

  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Gallery</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Gallery</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery-bg">
        <div className="container">
          <div className="row">
            {holdGalleryData &&
              holdGalleryData?.map((item, i) => {
                return (
                  <div key={i} className="col-sm-3">
                    <div className="main-gallery">
                      <img src={item.attributes.url} alt="" />
                    </div>
                  </div>
                );
              })}

            {/* <div className="col-sm-3">
              <div className="main-gallery">
                <img src={Pharmaceutics} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={Pharmacognosy} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={Pharmacology} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={LibraryImg} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={FacilitiesImg} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={LaboratoryImg} alt="" />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="main-gallery">
                <img src={SliderOne} alt="" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
