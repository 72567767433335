import React from "react";
import { Link } from "react-router-dom";
import PdfLoader from "../components/Pdf/PdfLoader";
import { ANTI_RAGING_CELL } from "../document";

const AntiagingCommittee = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Anti Ragging Committee And Squad</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  Anti Ragging Committee And Squad
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="prospectus-structure">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10">
              <PdfLoader pdfUrl={ANTI_RAGING_CELL} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AntiagingCommittee;
