import React, { useEffect, useState } from "react";

import Slider from "../components/Slider/Slider";
import { Link } from "react-router-dom";
import { FaGraduationCap } from "react-icons/fa";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  AboutFirstImg,
  AboutSecondImg,
  AboutThirdImg,
  DirectorMsg,
  IsoLogo,
  PciLogo,
  UdyamLogo,
  WbsctLogo,
} from "../images";
import { IsoCertificate, Pci, UdyamCertificate, Wbsct } from "../document";
import { toast } from "react-toastify";
import {
  gallerySection,
  makeAnContact,
  newNotice,
} from "../Service/Home/index";
import { EmailValidate, VisibleError } from "../components/Helper/VisibleError";
const Home = () => {
  // const [isRunning, setIsRunning] = useState(true);
  const [allNoticeStock, setAllNoticeStock] = useState([]);
  const [holdGalleryData, setHoldGalleryData] = useState([]);
  const [handelContactError, setHandelContactError] = useState({
    name: false,
    email: false,
    mobileNo: false,
    massage: false,
    emailValidate: false,
  });

  const [handelContact, setHandelContact] = useState({
    name: "",
    email: "",
    mobileNo: "",
    massage: "",
  });

  const handleChange = (e, max = 10) => {
    let { name, value } = e.target;
    if (name === "mobileNo") {
      if (value > max) {
        value = value?.slice(0, max);
      }
    }

    setHandelContact((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handelSubmit = async () => {
    let checkError = false;
    if (handelContact.name === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          name: true,
        };
      });
      checkError = true;
    }
    if (handelContact.email === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          email: true,
        };
      });
      checkError = true;
    } else if (!EmailValidate(handelContact.email)) {
      setHandelContactError((item) => {
        return {
          ...item,
          emailValidate: true,
        };
      });
      checkError = true;
    }
    if (handelContact.mobileNo === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          mobileNo: true,
        };
      });
      checkError = true;
    }
    if (handelContact.massage === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          massage: true,
        };
      });
      checkError = true;
    }
    if (checkError) {
      return;
    }
    try {
      const payload = {
        data: {
          name: handelContact.name,
          email: handelContact.email,
          phone: handelContact.mobileNo,
          description: handelContact.massage,
        },
      };
      const res = await makeAnContact(payload);
      if (res?.status === 200) {
        toast.success("Send Message Successfully");
        setHandelContact({
          name: "",
          email: "",
          mobileNo: "",
          massage: "",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getNotice = async () => {
    try {
      const res = await newNotice();
      setAllNoticeStock(res.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getGallery = async () => {
    try {
      const res = await gallerySection();
      setHoldGalleryData(res?.data?.data?.attributes?.files?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  // const handleMouseOver = () => {
  //   setIsRunning(false);
  // };

  // const handleMouseOut = () => {
  //   setIsRunning(true);
  // };
  useEffect(() => {
    getNotice();
    getGallery();
  }, []);

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
  return (
    <>
      <Slider />
      {/* services area */}
      <div className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
              <Link to={IsoCertificate} target="_blank">
                <div className="services-box">
                  <div className="services-inner">
                    <div className="icon">
                      <img src={IsoLogo} alt="Udyam logo" />
                    </div>
                    <div className="text">
                      <h5>ISO Certificate</h5>
                      {/* <p>
                      Seamlessly visualize quality ellectual capital without
                      superior collaboration and idea sharing listically
                    </p>
                    <Link to="/">
                      Read More <BsArrowRight />
                    </Link> */}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
              <Link to={UdyamCertificate} target="_blank">
                <div className="services-box active">
                  <div className="services-inner">
                    <div className="icon">
                      <img src={UdyamLogo} alt="PCI logo" />
                    </div>
                    <div className="text">
                      <h5>Udyam Certificate</h5>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
              <Link to={Pci} target="_blank">
                <div className="services-box active">
                  <div className="services-inner">
                    <div className="icon">
                      <img src={PciLogo} alt="ISO logo" />
                    </div>
                    <div className="text">
                      <h5>Approval of PCI</h5>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
              <Link to={Wbsct} target="_blank">
                <div className="services-box">
                  <div className="services-inner">
                    <div className="icon">
                      <img src={WbsctLogo} alt="WBSCT logo" />
                    </div>
                    <div className="text">
                      <h5>WBSCT & VE & SD</h5>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mandatory">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-md-3 col-lg-2">
              <p>Latest News:</p>
            </div>
            <div className="col-sm-9 col-md-9 col-lg-10">
              <marquee width="100%" direction="left">
                {allNoticeStock && (
                  <p className="mb-0">
                    {
                      allNoticeStock[allNoticeStock.length - 1]?.attributes
                        ?.description
                    }
                  </p>
                )}
              </marquee>
            </div>
          </div>
        </div>
      </div>
      {/* about area */}
      <div className="about-area">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <img src={AboutFirstImg} className="about_first" alt="" />
                </div>
                <div className="col-sm-6">
                  <img
                    src={AboutSecondImg}
                    className="about_second mb-3"
                    alt=""
                  />
                  <img src={AboutThirdImg} className="about_second" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-title">
                <h5>
                  <FaGraduationCap /> About Our College
                </h5>
                <h2>A Few Words About the College</h2>
                <p className="txt-clr">
                  Bengal Education of Pharmacy is a unique exemplary educational
                  institute for pharmacy,for the purpose of providing standard
                  education and positivity in the field of pharmacy and socially
                  aware and update with pharmacy and also for wellbeing of the
                  society.
                </p>
                <p className="txt-clr">
                  High standard of technical education with digital teaching
                  through smart classes, well- equipped laboratories with safety
                  measures, online videos and virtual interaction with students
                  created and expanded access to modern education, also the
                  library is full of vital books and journals along with access
                  to online journals and books.
                </p>
              </div>
              <div className="about-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="about-content">
                      <ul className="mt-4">
                        <li>
                          <div className="content">
                            {/* <div className="num">
                              <span>01</span>
                            </div> */}
                            <div className="text">
                              <h3>Our Vision</h3>
                              <p>
                                To create the pharmacy institution as “centre of
                                excellence” in learning, knowledge, creativity,
                                positivity and discipline and to prepare the
                                students to participate in pharmacy education
                                and research as well as in social activities.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="content">
                            {/* <div className="num">
                              <span>02</span>
                            </div> */}
                            <div className="text">
                              <h3>Our Mission</h3>
                              <p>
                                To impart leadership attitude, entrepreneurship,
                                co-operative spirit, cultured outlook, ethical
                                values, social responsibilities and healthy
                                habits among the students to contribute to the
                                technological, economic and social development
                                in the region.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* features area */}
      <div className="features-area">
        <div className="animations-10">
          <img
            src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/an-img-10.png"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row align-items-center message-reverse">
            <div className="col-lg-6 col-md-12">
              <div className="features-content">
                <h2>Director's Message</h2>
                <p>
                  A College, which consistently works towards enriching the
                  lives of its students. Our premier faculty is committed to
                  mentoring and guiding our students, exploiting their potential
                  and their thirst for success. With our State-of-the-art
                  infrastructure and periodic visits from distinguished guest
                  lecturers and experts, Bengal Education of Pharmacy creates
                  the best environment for learning in every sense of the term.
                  We believe that student life is incomplete without the spirit
                  of camaraderie and fellowship. Hence, our sprawling, vibrant
                  campus offers a plethora of extra curricular activities to
                  enable our students to grow fully as individuals. Ultimately,
                  Bengal Education of Pharmacy believes in creating ideal
                  pharmacist with the spirit and knowledge to commandeer the
                  sphere of teaching.
                </p>
                {/* <ul>
                  <li>
                    <div className="step-box">
                      <div className="icon-box">
                        <img
                          src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/fea-icon01.png"
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h3>Skilled Teachers</h3>
                        <p>
                          Special wedding garments are often worn, and the
                          ceremony is sttimes followed by a wedding reception.
                          Music, poetry, prayers, or readings from.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="step-box">
                      <div className="icon-box">
                        <img
                          src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/fea-icon01.png"
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h3>Skilled Teachers</h3>
                        <p>
                          Special wedding garments are often worn, and the
                          ceremony is sttimes followed by a wedding reception.
                          Music, poetry, prayers, or readings from.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="step-box">
                      <div className="icon-box">
                        <img
                          src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/fea-icon01.png"
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h3>Skilled Teachers</h3>
                        <p>
                          Special wedding garments are often worn, and the
                          ceremony is sttimes followed by a wedding reception.
                          Music, poetry, prayers, or readings from.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="features-img director-img">
                <img src={DirectorMsg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features-gallery">
        <div className="container">
          <div className="row">
            <OwlCarousel className="slider-items owl-carousel" {...options}>
              {holdGalleryData &&
                holdGalleryData?.map((item, i) => {
                  return (
                    <div key={i} className="item">
                      <div className="gallery-main">
                        <img src={item.attributes.url} alt="" />
                      </div>
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* contact area */}
      <div className="contact-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="notice">
                <div className="notice_title">
                  <h3>News And Notice</h3>
                </div>
                <div className="notice_list">
                  <div
                  // className={`marquee ${isRunning ? "running" : ""}`}
                  // onMouseOver={handleMouseOver}
                  // onMouseOut={handleMouseOut}
                  >
                    <ul>
                      {allNoticeStock &&
                        allNoticeStock?.map((item, i) => (
                          <li key={i}>
                            {item.attributes.files.data?.map((ele, j) => (
                              <Link
                                key={j}
                                to={ele.attributes.url}
                                target="_blank"
                              >
                                {item?.attributes?.description}
                              </Link>
                            ))}
                            {item.attributes.isNew === true && (
                              <img
                                src="https://www.eiti.org.in/assets/froantend/images/new.gif"
                                alt=""
                              />
                            )}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="contact-bg">
                <h2 className="mb-4"> Make An Contact</h2>
                <div className="contact-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <input
                          value={handelContact.name}
                          onChange={(e) => {
                            handleChange(e);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                name: false,
                              };
                            });
                          }}
                          type="text"
                          name="name"
                          placeholder="Name"
                          className="form-control"
                        />
                        <VisibleError
                          show={handelContactError.name}
                          name="Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="form-control"
                          value={handelContact.email}
                          onChange={(e) => {
                            handleChange(e);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                email: false,
                                emailValidate: false,
                              };
                            });
                          }}
                        />
                        <VisibleError
                          show={handelContactError.email}
                          name="Email"
                        />

                        {handelContactError.emailValidate ? (
                          <span
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingLeft: "10px",
                            }}
                          >
                            Please Enter a Valid Email!
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <input
                          type="tel"
                          name="mobileNo"
                          placeholder="Number"
                          className="form-control"
                          value={handelContact.mobileNo}
                          onChange={(e) => {
                            handleChange(e, 10);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                mobileNo: false,
                              };
                            });
                          }}
                        />
                        <VisibleError
                          show={handelContactError.mobileNo}
                          name="Number"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <textarea
                          type="text"
                          name="massage"
                          rows={5}
                          placeholder="Write your massage"
                          className="form-control"
                          value={handelContact.massage}
                          onChange={(e) => {
                            handleChange(e);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                massage: false,
                              };
                            });
                          }}
                        ></textarea>
                        <VisibleError
                          show={handelContactError.massage}
                          name="Write your massage"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      {/* <Link to="/">Submit Now</Link> */}
                      <button className="btn" onClick={handelSubmit}>
                        Submit Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
