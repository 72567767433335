import React from "react";
import { FaGraduationCap } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AboutFirstImg, AboutSecondImg, AboutThirdImg } from "../images";

const Overview = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Overview</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Overview</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* about area */}
      <div className="about-area bg-white">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <img src={AboutFirstImg} className="about_first" alt="" />
                </div>
                <div className="col-sm-6">
                  <img
                    src={AboutSecondImg}
                    className="about_second mb-3"
                    alt=""
                  />
                  <img src={AboutThirdImg} className="about_second" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-title">
                <h5>
                  <FaGraduationCap /> About Our College
                </h5>
                <h2>A Few Words About the College</h2>
                <p className="txt-clr">
                  Bengal Education of Pharmacy is a unique exemplary educational
                  institute for pharmacy,for the purpose of providing standard
                  education and positivity in the field of pharmacy and socially
                  aware and update with pharmacy and also for wellbeing of the
                  society.
                </p>
                <p className="txt-clr">
                  High standard of technical education with digital teaching
                  through smart classes, well- equipped laboratories with safety
                  measures, online videos and virtual interaction with students
                  created and expanded access to modern education, also the
                  library is full of vital books and journals along with access
                  to online journals and books.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
