import apiClient from "../../Config/apiConfig";
import { endpoints } from "../../Config/endpoints";

const makeAnContact = async (body) => {
  try {
    const res = await apiClient.post(endpoints.makeContactPost, body);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
const contactToMail = async (body) => {
  try {
    const res = await apiClient.post(endpoints.emailContact, body);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
const newNotice = async () => {
  try {
    const res = await apiClient.get(endpoints.newsAndNoticeGet);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
const gallerySection = async () => {
  try {
    const res = await apiClient.get(endpoints.gallerySectionGet);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export { makeAnContact, gallerySection, newNotice, contactToMail };
