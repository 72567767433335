import React from "react";
import { FaBars, FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import "./Header.css";
import { CallIcon, EmailIcon, Logo } from "../../images";

const Header = () => {
  return (
    <>
      <div className="top_header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="top-btn">
                <Link to="https://account.vedmarg.com/bengal-education-of-pharmacy-949/admission/form">
                  Admission form
                </Link>
                <Link to="https://account.vedmarg.com/login">Admin login</Link>
                <Link to="https://account.vedmarg.com/login">
                  Student login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-mobile-menu site-navbar-target">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle">
              <RxCross1 />
            </span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>
      <div className="site-navbar-wrap">
        <div className="header-top">
          <div className="side-gap">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-3 ">
                  <div className="header-social">
                    <span>
                      Follow us:-
                      <Link
                        to="https://www.facebook.com/bengal.eop?mibextid=LQQJ4d"
                        target="_blank"
                      >
                        <FaFacebook />
                      </Link>
                      <Link
                        to="https://www.instagram.com/bengal_pharmacy_official/?igsh=bjQxY2NuNG9xcnJu"
                        target="_blank"
                      >
                        <FaInstagram />
                      </Link>
                      <Link
                        to="https://web.whatsapp.com/send/?phone=7601932023&text&type=phone_number&app_absent=0"
                        target="_blank"
                      >
                        <FaWhatsapp />
                      </Link>
                    </span>
                  </div>
                </div>
                <div className="col-lg-8 col-md-9 text-right">
                  <div className="header-cta">
                    <ul className="d-flex align-items-center">
                      <li>
                        <div className="top-btn">
                          <Link to="https://account.vedmarg.com/bengal-education-of-pharmacy-949/admission/form">
                            Admission form
                          </Link>
                          <Link to="https://account.vedmarg.com/login">
                            Admin login
                          </Link>
                          <Link to="https://account.vedmarg.com/login">
                            Student login
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="call-box">
                          <div className="icon">
                            <img src={CallIcon} alt="" />
                          </div>
                          <div className="text">
                            <span>Call Now !</span>
                            <strong>
                              <Link to="tel:+91 7601932023">
                                +91 7601932023
                              </Link>
                            </strong>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="call-box">
                          <div className="icon">
                            <img src={EmailIcon} alt="" />
                          </div>
                          <div className="text">
                            <span>Email Now</span>
                            <strong>
                              <Link to="to:bengal.eop@gmail.com">
                                bengal.eop@gmail.com
                              </Link>
                            </strong>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-navbar site-navbar-target js-sticky-header">
          <div className="side-gap">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-2 col-md-4 col-sm-9 col-8 main-logo">
                  <div className="d-flex align-items-center">
                    <div>
                      <Link to="/">
                        <img src={Logo} alt="logo" />
                      </Link>
                    </div>
                    {/* <div className="institute_name">
                      <h3>Ranibazar</h3>
                      <p>Teacher Training Institute</p>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-10 col-md-8 col-sm-3 col-4">
                  <nav className="site-navigation text-right" role="navigation">
                    <div className="container">
                      <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                        <Link
                          to="/"
                          className="site-menu-toggle js-menu-toggle text-white"
                        >
                          <span className="icon-menu h3">
                            <FaBars />
                          </span>
                        </Link>
                      </div>
                      <ul className="site-menu main-menu js-clone-nav d-none d-lg-block">
                        <li>
                          <Link to="/" className="nav-link">
                            Home
                          </Link>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            About Us
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link to="/governing-body" className="nav-link">
                                Governing Body
                              </Link>
                            </li>
                            <li>
                              <Link to="/overview" className="nav-link">
                                Overview
                              </Link>
                            </li>
                            <li>
                              <Link to="/principal-desk" className="nav-link">
                                Principal Desk
                              </Link>
                            </li>
                            <li>
                              <Link to="/president-desk" className="nav-link">
                                President Desk
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Admission
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link
                                to="/prospectus-structure"
                                className="nav-link"
                              >
                                Prospectus & Fees Structure
                              </Link>
                            </li>
                            {/* <li>
                              <Link to="admission-enquiry" className="nav-link">
                                Admission Enquiry
                              </Link>
                            </li> */}
                            <li>
                              <Link to="/courses-offered" className="nav-link">
                                Courses Offered
                              </Link>
                            </li>
                            <li>
                              <Link to="/eligibility" className="nav-link">
                                Eligibility
                              </Link>
                            </li>
                            {/* <li className="has-children">
                              <Link
                                to="#"
                                target="_blank"
                                className="nav-link sub-menu-sub"
                              >
                                Trade License
                              </Link>
                              <ul className="dropdown">
                                <li>
                                  <Link to="/ladakh" className="nav-link">
                                    Ladakh
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/himachal-pradesh"
                                    className="nav-link"
                                  >
                                    Himachal Pradesh
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/kashmir" className="nav-link">
                                    Kashmir
                                  </Link>
                                </li>
                              </ul>
                            </li> */}
                          </ul>
                        </li>
                        <li>
                          <Link to="/faculties" className="nav-link">
                            Faculties
                          </Link>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Academics
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link to="/department" className="nav-link">
                                Department
                              </Link>
                            </li>
                            <li>
                              <Link to="/syllabus" className="nav-link">
                                Syllabus
                              </Link>
                            </li>
                            {/* <li>
                              <Link to="Topper" className="nav-link">
                                Topper
                              </Link>
                            </li>
                            <li>
                              <Link to="Publications" className="nav-link">
                                Publications
                              </Link>
                            </li>
                            <li>
                              <Link to="Student’s Area" className="nav-link">
                                Student’s Area
                              </Link>
                            </li> */}
                          </ul>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Infrastructure
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link to="/classroom" className="nav-link">
                                Classroom
                              </Link>
                            </li>
                            <li>
                              <Link to="/library" className="nav-link">
                                Library
                              </Link>
                            </li>
                            <li>
                              <Link to="/facilities" className="nav-link">
                                Facilities
                              </Link>
                            </li>
                            <li>
                              <Link to="/laboratory" className="nav-link">
                                Laboratory
                              </Link>
                            </li>
                            <li>
                              <Link to="/gallery" className="nav-link">
                                Gallery
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="has-children">
                          <Link href="#" className="nav-link">
                            Special Cells & Committee
                          </Link>
                          <ul className="dropdown arrow-top">
                            <li>
                              <Link
                                to="/antiaging-committee-and-squad"
                                className="nav-link"
                              >
                                Anti Ragging Committee And Squad
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                to="Anti-Discrimination Cell"
                                className="nav-link"
                              >
                                Anti-Discrimination Cell
                              </Link>
                            </li> */}
                            <li>
                              <Link
                                to="/gender-sensitization-cell"
                                className="nav-link"
                              >
                                Gender Sensitization Cell
                              </Link>
                            </li>
                            <li>
                              <Link to="/sc-st-cell" className="nav-link">
                                SC/ST CELL
                              </Link>
                            </li>
                            <li>
                              <Link to="/guidance-cell" className="nav-link">
                                Guidance Cell
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/internal-complaint"
                                className="nav-link"
                              >
                                Internal Complaint
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/training-placement" className="nav-link">
                            Training & Placement
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact-us" className="nav-link">
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
