import React from "react";
import { Link } from "react-router-dom";
import { ClassRoom } from "../images";

const Classroom = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Classroom</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Classroom</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="infrastructure-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="infrastructure-content">
                <p>
                  The classrooms are well designed with optimal room for natural
                  light and air. A blend of traditional black board presentation
                  and modern methods is opted for teaching. It enables the
                  teachers to teach in a unique and innovative manner which
                  makes the students feel involved in the teaching.
                </p>
              </div>
            </div>
            <div className="col-lg-4 align-self-center">
              <img src={ClassRoom} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Classroom;
