import React, { useState } from "react";
import { Link } from "react-router-dom";
import { EmailValidate, VisibleError } from "../components/Helper/VisibleError";
import { makeAnContact } from "../Service/Home";
import { toast } from "react-toastify";

const AdmissionEnquiry = () => {
  const [handelContactError, setHandelContactError] = useState({
    name: false,
    email: false,
    mobileNo: false,
    massage: false,
    emailValidate: false,
  });

  const [handelContact, setHandelContact] = useState({
    name: "",
    email: "",
    mobileNo: "",
    massage: "",
  });

  const handleChange = (e, max = 10) => {
    let { name, value } = e.target;
    if (name === "mobileNo") {
      if (value > max) {
        value = value?.slice(0, max);
      }
    }

    setHandelContact((prevState) => {
      return { ...prevState, [name]: value };
    });
  };
  const handelSubmit = async () => {
    let checkError = false;
    if (handelContact.name === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          name: true,
        };
      });
      checkError = true;
    }
    if (handelContact.email === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          email: true,
        };
      });
      checkError = true;
    } else if (!EmailValidate(handelContact.email)) {
      setHandelContactError((item) => {
        return {
          ...item,
          emailValidate: true,
        };
      });
      checkError = true;
    }
    if (handelContact.mobileNo === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          mobileNo: true,
        };
      });
      checkError = true;
    }
    if (handelContact.massage === "") {
      setHandelContactError((item) => {
        return {
          ...item,
          massage: true,
        };
      });
      checkError = true;
    }
    if (checkError) {
      return;
    }
    try {
      const payload = {
        data: {
          name: handelContact.name,
          email: handelContact.email,
          phone: handelContact.mobileNo,
          description: handelContact.massage,
        },
      };
      const res = await makeAnContact(payload);
      if (res?.status === 200) {
        toast.success("Send Message Successfully");
        setHandelContact({
          name: "",
          email: "",
          mobileNo: "",
          massage: "",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Admission Enquiry</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Admission Enquiry</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="courses-offered">
                <table>
                  <tbody>
                    <tr>
                      <td>D.Pharm</td>
                      <td>60</td>
                    </tr>
                    <tr>
                      <td>B.Pharm</td>
                      <td>Proposed </td>
                    </tr>
                    <tr>
                      <td>M.Pharm</td>
                      <td>Proposed</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="contact-bg">
                <h2 className="mb-4"> Make An Contact</h2>
                <div className="contact-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <input
                          value={handelContact.name}
                          onChange={(e) => {
                            handleChange(e);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                name: false,
                              };
                            });
                          }}
                          type="text"
                          name="name"
                          placeholder="Name"
                          className="form-control"
                        />
                        <VisibleError
                          show={handelContactError.name}
                          name="Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="form-control"
                          value={handelContact.email}
                          onChange={(e) => {
                            handleChange(e);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                email: false,
                                emailValidate: false,
                              };
                            });
                          }}
                        />
                        <VisibleError
                          show={handelContactError.email}
                          name="Email"
                        />

                        {handelContactError.emailValidate ? (
                          <span
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingLeft: "10px",
                            }}
                          >
                            Please Enter a Valid Email!
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <input
                          type="tel"
                          name="mobileNo"
                          placeholder="Number"
                          className="form-control"
                          value={handelContact.mobileNo}
                          onChange={(e) => {
                            handleChange(e, 10);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                mobileNo: false,
                              };
                            });
                          }}
                        />
                        <VisibleError
                          show={handelContactError.mobileNo}
                          name="Number"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-2">
                        <textarea
                          type="text"
                          name="massage"
                          rows={5}
                          placeholder="Write your massage"
                          className="form-control"
                          value={handelContact.massage}
                          onChange={(e) => {
                            handleChange(e);
                            setHandelContactError((item) => {
                              return {
                                ...item,
                                massage: false,
                              };
                            });
                          }}
                        ></textarea>
                        <VisibleError
                          show={handelContactError.massage}
                          name="Write your massage"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      {/* <Link to="/">Submit Now</Link> */}
                      <button className="btn" onClick={handelSubmit}>
                        Submit Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdmissionEnquiry;
