import "./App.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import PrincipalDesk from "./pages/PrincipalDesk";
import GovtBody from "./pages/GovtBody";
import PresidentDesk from "./pages/PresidentDesk";
import Overview from "./pages/overview";
import CoursesOffered from "./pages/CoursesOffered";
import Eligibility from "./pages/Eligibility";
import Faculties from "./pages/Faculties";
import Department from "./pages/Department";
import Classroom from "./pages/Classroom";
import ProspectusStructure from "./pages/ProspectusStructure";
import Library from "./pages/Library";
import Facilities from "./pages/Facilities";
import Laboratory from "./pages/Laboratory";
import Syllabus from "./pages/Syllabus";
import Gallery from "./pages/Gallery";
import AntiragingCommittee from "./pages/AntiragingCommittee";
import ScStCell from "./pages/ScStCell";
import InternalComplaint from "./pages/InternalComplaint";
import TrainingPlacement from "./pages/TrainingPlacement";
import GuidanceCell from "./pages/GuidanceCell";
import GenderSensitization from "./pages/GenderSensitization";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="governing-body" element={<GovtBody />} />
          <Route path="overview" element={<Overview />} />
          <Route path="principal-desk" element={<PrincipalDesk />} />
          <Route path="president-desk" element={<PresidentDesk />} />
          <Route
            path="prospectus-structure"
            element={<ProspectusStructure />}
          />
          <Route path="courses-offered" element={<CoursesOffered />} />
          <Route path="eligibility" element={<Eligibility />} />
          <Route path="faculties" element={<Faculties />} />
          <Route path="department" element={<Department />} />
          <Route path="syllabus" element={<Syllabus />} />
          <Route path="classroom" element={<Classroom />} />
          <Route path="library" element={<Library />} />
          <Route path="facilities" element={<Facilities />} />
          <Route path="laboratory" element={<Laboratory />} />
          <Route path="gallery" element={<Gallery />} />
          <Route
            path="antiaging-committee-and-squad"
            element={<AntiragingCommittee />}
          />
          <Route
            path="gender-sensitization-cell"
            element={<GenderSensitization />}
          />
          <Route path="sc-st-cell" element={<ScStCell />} />
          <Route path="guidance-cell" element={<GuidanceCell />} />
          <Route path="internal-complaint" element={<InternalComplaint />} />
          <Route path="training-placement" element={<TrainingPlacement />} />
          <Route path="contact-us" element={<Contact />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
