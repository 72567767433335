import React from "react";
import { BsFillEnvelopeCheckFill } from "react-icons/bs";
import {
  FaFacebookF,
  FaInstagram,
  FaLocationArrow,
  FaPhoneVolume,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer className="main-footer">
        <div className="side-gap">
          <div className="container">
            <div className="row">
              <div className="column col-lg-4 col-md-6">
                <div className="widget contact-widget">
                  <h3 className="widget-title">About Us</h3>
                  <div className="about-widget">
                    <p className="text">
                      Bengal Education of Pharmacy is a unique exemplary
                      educational institute for pharmacy,for the purpose of
                      providing standard education and positivity in the field
                      of pharmacy and socially aware and update with pharmacy
                      and also for wellbeing of the society.
                    </p>
                    <ul className="social-links">
                      <li>
                        <Link
                          to="https://www.facebook.com/bengal.eop?mibextid=LQQJ4d"
                          target="_blank"
                        >
                          <FaFacebookF />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.instagram.com/bengal_pharmacy_official/?igsh=bjQxY2NuNG9xcnJu"
                          target="_blank"
                        >
                          <FaInstagram />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://wa.me/7601932023" target="_blank">
                          <FaWhatsapp />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="column col-lg-4 col-md-6">
                <div className="widget links-widget">
                  <h3 className="widget-title">Information</h3>
                  <div className="widget-content footer-links">
                    <ul>
                      <li>
                        <Link to="/overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/gallery">Gallery</Link>
                      </li>
                      <li>
                        <Link to="/courses-offered">Courses Offered</Link>
                      </li>
                      <li>
                        <Link to="/faculties">Faculties</Link>
                      </li>

                      <li>
                        <Link to="/department">Department</Link>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                      <li>
                        <Link to="/laboratory">Laboratory</Link>
                      </li>
                      <li>
                        <Link to="/internal-complaint">Internal Complaint</Link>
                      </li>
                      <li>
                        <Link to="/classroom">Classroom</Link>
                      </li>
                      <li>
                        <Link to="/training-placement">
                          Training & Placement
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="column col-lg-4 col-md-6">
                <div className="widget contact-widget">
                  <h3 className="widget-title">Contact Us</h3>
                  <div className="widget-content">
                    <ul className="contact-info">
                      <li>
                        <div className="icon">
                          <FaPhoneVolume />
                        </div>
                        <div className="text">
                          Helpline 24/7 <br />
                          <Link to="tel:7601932023">+91 7601932023</Link> <br />
                          <Link to="tel:7601932024">+91 7601932024</Link>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <BsFillEnvelopeCheckFill />
                        </div>
                        <div className="text">
                          <Link to="/">Email Us</Link>
                          <br />
                          <Link to="mailto:bengal.eop@gmail.com">
                            bengal.eop@gmail.com
                          </Link>
                          <br />
                          <Link to="mailto:beop.principle@gmail.com">
                            beop.principle@gmail.com
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <FaLocationArrow />
                        </div>
                        <div className="text">
                          Jemo, White House, Kandi, Murshidabad, WB, 742140.
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="widget about-widget mb-0">
                  <ul className="social-links">
                    <li>
                      <Link to="/">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <BsTwitter />
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="wrapper-box">
              <div className="text">
                &copy; 2024 Bengal Education of Pharmacy, All rights reserved.
              </div>
              <div className="text">
                Design and Developed by
                <Link to="https://www.yeasitech.com/" target="_blank">
                  &nbsp;YEASITECH
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="fixed-social">
        <div>
          <Link
            to="https://www.facebook.com/bengal.eop?mibextid=LQQJ4d"
            className="fixed-facebook"
            target="_blank"
          >
            <i className="fa fa-facebook"></i> <span>Facebook</span>
          </Link>
        </div>
        <div>
          <Link
            to="https://www.instagram.com/bengal_pharmacy_official/?igsh=bjQxY2NuNG9xcnJu"
            className="fixed-instagram"
            target="_blank"
          >
            <i className="fa fa-instagram"></i> <span>Instagram</span>
          </Link>
        </div>
        <div>
          <Link
            to="https://web.whatsapp.com/send/?phone=7601932023&text&type=phone_number&app_absent=0"
            className="fixed-wp"
            target="_blank"
          >
            <i className="fa-brands fa-whatsapp"></i> <span>WhatsApp</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
