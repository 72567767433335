import React from "react";

export const VisibleError = ({ show, name = "value" }) => {
  return (
    <span
      style={{
        visibility: show ? "visible" : "hidden",
        fontSize: "13px",
        color: "red",
        paddingLeft: "10px",
      }}
    >
      {`Please Enter ${name}`}
    </span>
  );
};
export const EmailValidate = (emailId) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let reg = re.test(String(emailId).toLowerCase());
  return reg;
};
