import React from "react";
import { Link } from "react-router-dom";
import { SumanFaculties } from "../images";

const PrincipalDesk = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Principal Desk</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Principal Desk</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="features-area Principal_sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="features-img text-center">
                <img src={SumanFaculties} alt="" />
                <h4>Suman Kanrar</h4>
                <p>(Principal)</p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="features-content">
                <p>
                  Dear students, I welcome you to the world of pharmacy and
                  contribute to the society. We “The Pharmacist” are not only
                  concerned with medicines, but also promoting health. The
                  pharmacists play a pivotal role in the health care system. The
                  role of pharmacist is expanding from a compounder to a patient
                  counselor and researcher. Pharmaceutical industry has shown
                  tremendous growth rate in Indian Pharmaceutical market in last
                  few years. The Pharma industry is an ever growing industry and
                  every day new development with respect to the pharmaceutical
                  technology is evident. Today a new era of clinical research
                  has opened many new career opportunities for the budding
                  pharmacists.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrincipalDesk;
