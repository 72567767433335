import React from "react";
import { Link } from "react-router-dom";
import { LaboratoryImg } from "../images";

const Laboratory = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Laboratory</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Laboratory</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="infrastructure-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="infrastructure-content">
                <h3>Pharmaceutical Chemistry Lab</h3>
                <p>
                  Pharmaceutical Chemistry Lab is meant for testing and
                  identification of impurities; synthesis and purification of
                  chemical compounds; identification of medicinally active
                  compounds; and analysis of biological samples like urine,
                  blood, etc.
                </p>
                <p>
                  Pharmaceutical Analysis Lab is suitable for analysis, quality
                  assessment and quality control studies of drugs on finished
                  products.
                </p>
                <p>
                  Central Instrumentation Lab provides instrumentation facility
                  for students and faculty for conducting analytical practical &
                  research work. This laboratory is well equipped with all
                  necessary instrumental facilities such as: Visible & UV
                  Spectrophotometer, Flame Photometer, Conductivity Meter,
                  Potentiometer, Digital Balance, Flourimeter, Digital pH Meter,
                  Brookfield Viscometer, etc.
                </p>
              </div>
            </div>
            <div className="col-lg-4 align-self-center">
              <img src={LaboratoryImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Laboratory;
