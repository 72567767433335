import React from "react";
import { Link } from "react-router-dom";
import { LibraryImg } from "../images";

const Library = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Library</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Library</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="infrastructure-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="infrastructure-content">
                <p>
                  The Institute has a rich collection of books and journals. A
                  well stocked library is a back bone of any institution. The
                  library of the pharmacy college comprises of a specious, well-
                  furnished and illuminated hall with a seating capacity for
                  more than 60 students. There is a well-developed library
                  having approximate 6500 text-books and reference books (about
                  800 titles), International National journals (Hard copy &
                  E-Journals) & E-Books for nurturing knowledge of the students.
                </p>
                <p>
                  The library is computerized. To add to it, there’s Internet
                  facility for online search that brings the world of
                  information to the students. The CD – ROM collection include
                  different subjects support the digital resources of the
                  library. The library is updated every year and newer journals,
                  books and abstracts are added to it. This helps the faculty
                  members and students to keep themselves abreast of recent
                  developments in the pharmaceutical sciences
                </p>
              </div>
            </div>
            <div className="col-lg-4 align-self-center">
              <img src={LibraryImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Library;
