import React from "react";
import { Link } from "react-router-dom";
import {
  ChamistryLabImg,
  Pharmaceutics,
  Pharmacognosy,
  Pharmacology,
  PharmacyLabImg,
} from "../images";

const Department = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Department</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Department</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="infrastructure-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="infrastructure-content">
                <h3>Department of Pharmaceutics</h3>
                <p>
                  Pharmaceutics help in training the students in drug
                  formulations like tablets, capsules, parenterals, ointments,
                  creams, eye drops, syrups, drug interaction, stability,
                  shelf-life studies, etc. Different aspects of filling,
                  packing, sealing, and labeling are studied by the students.
                  Various practical aspects of Pharmaceutics like Physical
                  Pharmacy, Unit Operation, Dispensing Pharmacy, Pharmaceutical
                  Technology, Biopharmaceutics, Community Pharmacy,
                  Pharmaceutical Engineering, etc. are taught to the students.
                </p>
                <p>
                  Main areas of research in the department of pharmaceutics
                  include –Novel drug delivery systems (Polymeric & lipid based
                  nanosystems), ocular drug delivery systems, transdermal drug
                  delivery system, mucoadhesive drug delivery system and use of
                  bio-polymers for NDDS.
                </p>
                <p>
                  Machine Room equipped with all the modern equipments and
                  instruments related to the production and in-house quality
                  control of tablets, capsules, liquids and injectibles.
                </p>
                <p>
                  Equipment available: Tablet Punching machine, Fluidized bed
                  drier, Double cone blender, Tray drier, Brook field
                  Viscometer, Filter press, Disintegration apparatus, Tablet
                  coating machine, Capsule filling machine, Ointment filling &
                  sealing machine, Friabilator, Ampoule sealing machine, Tablet
                  Hardness tester, IR moisture balance, etc.
                </p>
              </div>
            </div>
            <div className="col-lg-4 align-self-center">
              <img src={Pharmaceutics} alt="" />
            </div>
          </div>
          <div className="row message-reverse">
            <div className="col-lg-4 align-self-center">
              <img src={Pharmacognosy} alt="" />
            </div>
            <div className="col-lg-8 align-self-center">
              <div className="infrastructure-content">
                <h3>Department of Pharmacognosy</h3>
                <p>
                  The Pharmacognosy Lab is equipped with Autoclaves,
                  Microcentrifugator, Phase contrast microscope, 3D transmission
                  electronic and scanning electronic microscope, Soxhlet
                  apparatus, TLC apparatus with chamber, Microtome Blade,
                  Clevenger apparatus, Hot air oven, Muffle furnace, Camera
                  lucida etc. The primary objective of this lab is to provide
                  practical exposure to Undergraduates and Research Students on
                  different isolation and extraction techniques, to check the
                  contamination as well as adulteration of crude drugs.
                  Separation and purification of active constituents by
                  different chromatography etc.
                </p>
                <p>
                  Medicinal Garden:Medicinal plants have a great role in human
                  life and India has rich diversity of medicinal plants. The
                  garden is systematic and well equipped with medicinal plants
                  labelled properly with indigenous and scientific names. The
                  garden is looked after by gardener and trained faculty. All
                  the plant species were authentically identified by expert
                  taxonomists. The objective of establishing this garden is to
                  maintain a conservatory of the most common and important
                  species of medicinal and aromatic plants which are being used
                  in day-to-day primary health care.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="infrastructure-content">
                <h3>Department of Pharmacology</h3>
                <p>
                  Pharmacology department trains the students in subjects like
                  Anatomy, Physiology & Pathophysiology, Pharmacology &
                  Toxicology. Students are given facilities to understand
                  different systems of Human Anatomy & their functions &
                  pathology with tools like models, charts, permanent slides,
                  bones, skeletons, etc. Here students learn about general
                  pharmacology, drug dose, mechanism of action of drugs,
                  screening of drugs, etc. Pharmacology Labs are well equipped
                  with Rotarod, Pole Climbing Apparatus, Convulsiometer,
                  Analgesiometer, etc. & Computer software for the basic
                  demonstration of Pharmacology experiments is provided.
                </p>
                <p>
                  Animal House: The department has well established animal house
                  giving all the necessary care & facilities for hygienic
                  housing of laboratory animals like Wistar rats, albino mice,
                  etc. required for pre-clinical experiments. It has been
                  air-conditioned for better up keeping and care of lab animals.
                  Experiments on animals are done in accordance to the
                  guidelines given by CPCSEA. Regular meeting of Institutional
                  Animal Ethics Committee provides guidelines for breeding and
                  betterment of animals.
                </p>
              </div>
            </div>
            <div className="col-lg-4 align-self-center">
              <img src={Pharmacology} alt="" />
            </div>
          </div>
          <div className="row message-reverse">
            <div className="col-lg-4 align-self-center">
              <img src={ChamistryLabImg} alt="" />
            </div>
            <div className="col-lg-8 align-self-center">
              <div className="infrastructure-content">
                <h3>Department of Pharmaceutical Chemistry</h3>
                <p>
                  The Department of Pharmaceutical Chemistry is a branch of
                  pharmaceutical sciences that deals with the study of drugs and
                  drug compounds at the molecular level. This field combines
                  aspects of organic chemistry, biochemistry, and pharmacology
                  to understand the design, synthesis, characterization, and
                  mode of action of pharmaceutical agents.
                </p>
                <p>
                  Some key areas of focus within pharmaceutical chemistry
                  include:
                </p>
                <ul>
                  <li>
                    Drug discovery and development: Pharmaceutical chemists are
                    involved in the design and synthesis of new drugs, as well
                    as the optimization of existing drugs to improve their
                    efficacy, safety, and pharmacokinetic properties.
                  </li>
                  <li>
                    Medicinal chemistry: Medicinal chemistry involves the design
                    and synthesis of bioactive molecules with therapeutic
                    potential. This includes the identification of lead
                    compounds, structure-activity relationship (SAR) studies,
                    and the development of drug candidates with improved
                    pharmacological profiles.
                  </li>
                  <li>
                    Analytical chemistry: Analytical techniques are crucial for
                    characterizing the chemical composition of drugs and drug
                    formulations. Pharmaceutical chemists use analytical methods
                    such as spectroscopy, chromatography, and mass spectrometry
                    to assess the purity, stability, and quality of
                    pharmaceutical products.
                  </li>
                  <li>
                    Pharmacokinetics and pharmacodynamics: Understanding how
                    drugs are absorbed, distributed, metabolized, and excreted
                    in the body (pharmacokinetics) and how they exert their
                    effects (pharmacodynamics) is essential for optimizing drug
                    therapy and minimizing adverse effects.
                  </li>
                  <li>
                    Chemical biology: Chemical biology involves the application
                    of chemical principles and techniques to understand
                    biological systems and processes at the molecular level.
                    Pharmaceutical chemists may use chemical probes and imaging
                    agents to study drug-target interactions and cellular
                    signaling pathways.
                  </li>
                </ul>
                <p>
                  Overall, the Department of Pharmaceutical Chemistry plays a
                  critical role in advancing drug discovery, development, and
                  therapeutic innovation to improve human health and quality of
                  life.
                </p>
                <p>
                  Our pharmaceutical chemistry lab contains a variety of
                  specialized equipment for conducting experiments, analysis,
                  and research which includes:
                </p>
                <p>
                  Balances , Spectrophotometers , Chromatography systems , Mass
                  spectrometers Incubators and shakers , Fume hoods , pH meters
                  and titration , Safety equipment etc.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="infrastructure-content">
                <h3>Department of Social Pharmacy</h3>
                <p>
                  Social pharmacy holds significant importance in the field of
                  pharmacy and healthcare for several reasons:
                </p>
                <ul>
                  <li>Understanding Patient Behavior</li>
                  <li>Promoting Patient-Centered Care</li>
                  <li>Addressing Health Disparities</li>
                  <li>Optimizing Medication Use</li>
                  <li>Influencing Health Policy</li>
                  <li>Enhancing Interprofessional Collaboration</li>
                  <li>Advancing Pharmaceutical Education</li>
                </ul>
                <p>
                  Overall, social pharmacy plays a vital role in improving
                  patient care, promoting health equity, and advancing public
                  health by considering the social determinants that influence
                  health and healthcare utilization.
                </p>
                <p>
                  Our Social Pharmacy Laboratory is a specialized facility where
                  research and studies are conducted on the social aspects of
                  pharmacy practice and medication use. Our social pharmacy
                  laboratory typically focus on investigating how social
                  factors, such as culture, socioeconomic status, education, and
                  healthcare policies, influence the utilization of medications
                  and healthcare services.
                </p>
                <p>
                  Key components and activities of our Social Pharmacy
                  Laboratory may include:
                </p>
                <ul>
                  <li>
                    <b>Research Projects:</b> Conducting studies to explore
                    various aspects of medication use, adherence, and patient
                    outcomes from a social perspective. This could involve
                    surveys, interviews, observational studies, and intervention
                    trials.
                  </li>
                  <li>
                    <b>Data Analysis:</b> Utilizing statistical methods and
                    analytical tools to analyze data collected from research
                    studies, electronic health records, or other sources to
                    identify patterns and trends in medication use and
                    healthcare practices.
                  </li>
                  <li>
                    <b>Health Policy Analysis:</b> Evaluating the impact of
                    healthcare policies, regulations, and interventions on
                    medication access, affordability, and utilization, and
                    providing recommendations for policy improvements.
                  </li>
                  <li>
                    <b>Community Engagement:</b> Collaborating with local
                    communities, healthcare providers, and policymakers to
                    address issues related to medication access, health
                    disparities, and patient education.
                  </li>
                  <li>
                    <b>Education and Training:</b> Providing education and
                    training programs for pharmacy students, healthcare
                    professionals, and the public on topics related to social
                    pharmacy, including cultural competency, health literacy,
                    and patient-centered care.
                  </li>
                  <li>
                    <b>Interdisciplinary Collaboration:</b> Collaborating with
                    researchers from other disciplines such as sociology,
                    psychology, anthropology, and public health to gain insights
                    into the complex interactions between social factors and
                    medication use.
                  </li>
                  <li>
                    <b>Technology Integration:</b> Exploring the use of
                    technology, such as mobile health applications and
                    telemedicine, to improve medication adherence, communication
                    between patients and healthcare providers, and access to
                    healthcare services.
                  </li>
                  <li>
                    <b>Publications and Dissemination:</b> Publishing research
                    findings in scientific journals, presenting findings at
                    conferences, and disseminating knowledge to healthcare
                    professionals, policymakers, and the public through various
                    channels.
                  </li>
                </ul>
                <p>
                  Overall, our Social Pharmacy Laboratory serves as a hub for
                  advancing knowledge and understanding of the social dimensions
                  of pharmacy practice and medication use, with the ultimate
                  goal of improving patient outcomes and promoting health
                  equity.
                </p>
              </div>
            </div>
            <div className="col-lg-4 align-self-center">
              <img src={PharmacyLabImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Department;
