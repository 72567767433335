import React from "react";
import { Link } from "react-router-dom";

const Eligibility = () => {
  return (
    <>
      <div className="breadcrumb-area d-flex  p-relative align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Eligibility</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Eligibility</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="infrastructure-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="infrastructure-content">
                <h3>Eligibility Criteria for D. Pharm admission -</h3>
                <p>
                  To take admission, in D. Pharm courses, candidates are
                  required to:
                </p>
                <ul>
                  <li>
                    Have completed the age of 17 on or before 31st December of
                    the year of admission (2021).
                  </li>
                  <li>
                    Have passed 10 + 2 examinations or its equivalent
                    examination in the General Stream Courses along with
                    Physics, Chemistry and biology or mathematics.
                  </li>
                  <li>
                    Should have qualified CET conducted by WBSCTE & VE& SD on
                    the admission year (2021)
                  </li>
                </ul>
                <p>
                  Candidates passing 10+2 examination or equivalent examination
                  in Vocational Stream are not eligible for Admission in D.
                  Pharm as stated by the Pharmacy Council of India.
                </p>
              </div>
            </div>
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Eligibility;
